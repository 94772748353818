.bx-brand-container {
    position: absolute;
    z-index: 1;
    top: 0.5em;
    height: 4rem;
    width: auto;
    background-color: black;
}

.bx-brand-text {
    font-size: 2em;
    font-family: Courier New;
    color: white;
    vertical-align: middle;
}

.hoverable{
    display:inline-block;
    backface-visibility: hidden;
    vertical-align: middle;
    position:relative;
    box-shadow: 0 0 1px rgba(0,0,0,0);
    transform: translateZ(0);
    transition-duration: .3s;
    transition-property:transform;
}

.hoverable:before{
    position:absolute;
    pointer-events: none;
    z-index:-1;
    content: '';
    top: 100%;
    left: 5%;
    height:10px;
    width:90%;
    opacity:0;
    background: -webkit-radial-gradient(center, ellipse, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 80%);
    background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 80%);
    transition-duration: 0.3s;
    transition-property: transform, opacity;
}

.hoverable:hover, .hoverable:active{
    transform: translateY(-3px);
}
.hoverable:hover:before, .hoverable:active:before, .hoverable:focus:before{
    opacity: 1;
    transform: translateY(-3px);
}
